<template>
  <div>
    <h1>Muuda</h1>
    <div class="mb-2">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-text-field
            v-model="title"
            label="Pealkiri"
            required
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            outlined
            label="Kindlustusliik"
            v-model="insurance_type"
            :items="insurance_types"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-text-field
            v-model="recommendation_weight"
            label="Kaal soovituse arvutamisel"
            required
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-select
            outlined
            label="Keel"
            v-model="lang"
            :items="languages"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <QueryBuilder
      :query="query"
      :lang="lang"
    > </QueryBuilder>
    <div class="mt-4 mb-8">
      <v-btn
        v-show="true"
        dark
        depressed
        color="#3d9732"
        @click="openDialog"
        class="mt-5 mr-2"
      >
        Salvesta
      </v-btn>
      <v-btn
        v-show="true"
        dark
        depressed
        color="red"
        @click="deleteCondition"
        class="mt-5"
      >
        Kustuta
      </v-btn>
    </div>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <div class="mt-4">
      <h2>Muudatuste ajalugu</h2>
      <v-data-table
        :headers="headers"
        :items="versions"
        item-key="guid"
        :hide-default-footer=true
        :items-per-page="30"
        class="mb-4"
      >
        <template v-slot:item.active_version="{ item }">
          <v-icon color="green" v-if="item.active_version">mdi-check</v-icon>
          <v-icon color="red" v-else>mdi-close</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="restore(item)" depressed color="#3d9732" dark right>
            Taasta see versioon
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Muudatuse sisu</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="changesComment"
                    label="Kommentaar"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Katkesta
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Salvesta
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
  import axios from "axios";
  import QueryBuilder from '../components/QueryBuilder/QueryBuilder';
  import ConfirmDialog from '../components/ConfirmDialog';

  export default {
    name: "Conditions",
    props: [
      'conditionId'
    ],
    components: {
      ConfirmDialog,
      QueryBuilder
    },
    data() {
      return {
        condition_id: null,
        title: '',
        insurance_type: '',
        lang: 'ET',
        recommendation_weight: 0,
        pre_save_query: {

        },
        query: {
          logicalOperator: 'AND',
          rules: [
            {
              "rule": "",
              "operator": "",
              "value": ""
            }
          ],
          tooltip: {
            "ET": "",
            "EN": "",
            "RU": "",
          },
          groups: []
        },
        insurance_types: [
          {
            text: 'Kasko',
            value: 'casco'
          },
          {
            text: 'Liikluskindlustus',
            value: 'mtpl'
          },
          {
            text: 'Kodukindlustus',
            value: 'home'
          },
          {
            text: 'Reisikindlustus',
            value: 'travel'
          }
        ],
        languages: [
          {
            text: 'Eesti',
            value: 'ET'
          },
          {
            text: 'Inglise',
            value: 'EN'
          },
          {
            text: 'Vene',
            value: 'RU'
          },
        ],
        versions: [],
        headers: [
          { text: 'Pealkiri', value: 'title' },
          { text: 'Kommentaar', value: 'comment' },
          { text: 'Muudatuse kuupäev', value: 'created_at' },
          { text: 'Aktiivne', value: 'active_version' },
          { text: '', value: 'actions', sortable: false }
        ],
        snackbar: false,
        text: 'Versioon taastatud',
        timeout: 5000,
        dialog: false,
        changesComment: ''
      };
    },
    mounted() {
      this.condition_id = this.$route.params.id;
      if (this.condition_id == undefined) {
        this.condition_id = this.conditionId;
      }
      this.fetchCondition(this.condition_id);
    },
    methods: {
      fetchCondition: function (condition_id) {
        let url = axios.defaults.baseURL + '/api/condition/' + condition_id;
        axios.get(url).then((response) => {
          this.title = response.data.title;
          this.insurance_type = response.data.insurance_type;
          this.recommendation_weight = response.data.recommendation_weight;
          this.query = response.data.config;
          this.versions = response.data.versions;
        });
      },
      openDialog: function() {
        this.dialog = true;
      },
      save: function () {
        let payload = {
          condition_id: this.condition_id,
          title: this.title,
          insurance_type: this.insurance_type,
          recommendation_weight: this.recommendation_weight,
          comment: this.changesComment,
          config: this.query
        };
        let url = axios.defaults.baseURL + '/api/condition/' + this.condition_id;
        axios.post(url, payload).then((response) => {
          console.log(response.data.error);
          if (response.data.error) {
            this.text = response.data.message;
            this.snackbar = true;
          } else {
            this.$router.push('/conditions');
          }
        });
      },
      restore: function (version) {
        let url = axios.defaults.baseURL + '/api/condition/restore/' + version.id;
        axios.get(url).then((response) => {
          this.fetchCondition(this.condition_id);
          this.text = 'Versioon taastatud';
          this.snackbar = true;
        });
      },
      deleteCondition: function () {
        this.$refs.confirm.open('Kustuta', 'Kas oled kindel, et soovid kustutada?').then((confirm) => {
          if (confirm) {
            let url = axios.defaults.baseURL + '/api/condition/' + this.condition_id;
            axios.delete(url).then((response) => {
              this.$router.push('/conditions');
            });
          }
        });
      },
    }
  }
</script>