<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      width="400"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            depressed
            @click="agree()"
          >
            Kinnitan
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            depressed
            @click="cancel()"
          >
            Katkesta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
    }),
    methods: {
      open(title, message) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        console.log(this.resolve);
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      }
    }
  }
</script>